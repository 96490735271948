:root,
body,
.App {
  background-color: #111111;
}
.App {
  text-align: center;

  min-height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: 1rem;
}

h1,
h2,
h3,
a,
p,
button {
  color: #cccccc;
}

text {
  fill: #cccccc;
}

button {
  background-color: transparent;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
  padding: 0.5rem 1rem;
}

button.button-selected {
  background-color: #005e00;
}

a:hover,
button:hover {
  background-color: #cccccc;
  color: #111111;
  cursor: pointer;
}

.data-offering {
  flex-basis: 15vw;
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.data-offering h3 {
  text-decoration: underline;
}

.data-offering li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.racing-bar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.race-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.App > * {
  padding: 2rem;
}
